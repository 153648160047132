import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { order } from 'Common/features';
import { SearchContext } from './SearchProvider';
import SearchBar from './SearchBar';
import SearchTabbedFacet from './SearchTabbedFacet';
import SearchResult from './SearchResult';
import SearchPagingSummary from './SearchPagingSummary';
import * as SearchApi from 'Common/features/search/api';
import * as ProductApi from 'Common/features/product/api';
import { useViewport } from 'Common/hooks';
import { InfiniteScroll, Spinner, VideoPlayerModal, Section, ResultsGrid, HTMLRender } from 'Common/components/ui';
import ProductQuickview from './ProductQuickview';
import { SearchFacetBreadcrumbs } from './SearchFacet';
import SearchFiltersMenu from './SearchFiltersMenu';
import { Cookie, Url } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';

/**
 *
 * @param {SearchContext} context
 * @param {boolean} appendResults
 * @returns {SearchContext}
 */
async function defaultSearch(context, appendResults) {
    Url.current.addParam('query', context.query).apply();
    return SearchContext.fromSearchApiResponse(
        context,
        await SearchApi.contentSearch(
            context.query,
            context.pageInfo.page,
            context.defaultPageSize,
            context.sortOptions.find((o) => o.selected)?.text,
            context.facets
        ),
        appendResults
    );
}

function updateRecents(relatedSearchCount, value) {
    let searches = [];

    for (let i in [...Array(relatedSearchCount)]) {
        const recent = Cookie.getCookieByName(`RecentSearch${i}`);

        if (!recent) continue;
        searches.push(recent);
    }

    if (searches.includes(value)) return;
    searches.unshift(value);

    if (searches.length > relatedSearchCount) {
        searches = searches.slice(0, relatedSearchCount);
    }

    for (let i = 0; i < searches.length; i++) {
        Cookie.createCookie(`RecentSearch${i}`, searches[i]);
    }
}

/**
 *
 * @param {SearchContext} context
 * @param {string} code
 * @returns {SearchContext}
 */
async function fetchProduct(context, code) {
    const response = await ProductApi.fetchProductDetails(code);

    if (response.errors.length || !response.products?.length) {
        console.warn('Failed to load product data for code', code);
        return context;
    }

    return SearchContext.fromProductApiResponse(context, response.products[0]);
}

const defaultContext = (intialContext) => SearchContext.create(intialContext);

export default function SearchBlock({
    className,
    style,
    initialQuery,
    defaultPageSize = 24,
    theme = 'default',
    quickviewMode = 'Filter',
    onSearch = defaultSearch,
    storeLocatorLink,
    relatedSearchCount,
    useChildTable,
    useRetailer = false,
    ...props
}) {
    const totalQty = useSelector(order.selectors.getTotalQuantity);

    const { useRecaptcha, googleRecaptchaV3Sitekey, checkoutPageLink, inputStatusTooltip, resultsNotFoundDetails } =
        props;

    const [hasSearchedInitial, setHasSearchedInitial] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [videoData, setVideoData] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const isMobile = useViewport().is.lt('md');
    /**
     * @type {[SearchContext, (action: SearchContextAction) => void]}
     */
    const [context, dispatch] = useReducer(
        SearchContext.reducer,
        defaultContext({ query: initialQuery, defaultPageSize: defaultPageSize })
    );

    const [quickviewIdx, setQuickviewIdx] = React.useState(-1);
    const quickviewData = React.useMemo(
        () => context.results?.find((_, i) => i === quickviewIdx)?.product,
        [context.results, quickviewIdx]
    );

    const facets = React.useMemo(() => {
        const tabfacet = context.facets?.find((f) => f.type === 'tabs');
        const filterFacets = context.facets?.filter((f) => f.type !== 'tabs');

        return {
            searchFacets: filterFacets,
            tabFacet: filterFacets.find((f) => f.options.find((o) => o.selected))
                ? {
                      ...tabfacet,
                      options: tabfacet.options
                          .filter((o) => o.value === 'product')
                          .map((o) => ({ ...o, count: context.pageInfo.total })),
                  }
                : tabfacet,
        };
    }, [context.facets, context.pageInfo]);

    const selectedTab = React.useMemo(
        () => facets.tabFacet?.options?.find((o) => o.selected)?.value,
        [facets.tabFacet]
    );

    const isDoneLoading = useMemo(() => {
        return context.pageInfo.page >= context.pageInfo.totalPages;
    }, [context]);

    /**
     * @type {(query: string) => void}
     */
    const setQuery = useCallback(
        (query) => {
            dispatch({
                type: 'setQuery',
                payload: query,
            });
        },
        [dispatch]
    );

    /**
     * @type {() => PromiseLike<void>} search
     */
    const search = useCallback(
        async (ctx, appendResults = false) => {
            try {
                setIsLoading(true);
                setQuickviewIdx(-1);
                dispatch({
                    type: 'setContext',
                    payload: await onSearch(ctx, appendResults),
                });
            } finally {
                setIsLoading(false);
            }
        },
        [onSearch, dispatch]
    );

    /**
     * @type {(query: FormEventHandler<HTMLFormElement>) => Promise<void>}
     */
    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            const ctx = SearchContext.updatePage(context, true);

            await search(ctx);
            updateRecents(relatedSearchCount, ctx.query);
        },
        [context, search, relatedSearchCount]
    );

    /*
    const onSort = useCallback(
        async (option) => {
            const ctx = SearchContext.selectSort(context, option);

            setSort(ctx.sortOptions);
            await search(ctx);
        },
        [context, search, setSort]
    );
    */

    const onChange = useCallback(
        async (updatedFacets) => {
            const ctx = SearchContext.setFacets(
                SearchContext.updatePage(context, true),
                updatedFacets,
                (f) => f.type === 'tabs'
            );

            dispatch({
                type: 'setFacets',
                payload: ctx.facets,
            });
            await search(ctx);
        },
        [context, search]
    );

    const onSearchCatagoryChange = useCallback(
        async (facet) => {
            const ctx = SearchContext.updateFacet(
                SearchContext.updatePage(context, true),
                (f) => f.id === facet.id,
                () => facet
            );

            dispatch({
                type: 'setFacets',
                payload: ctx.facets,
            });

            await search(ctx);
        },
        [context, search]
    );

    const onLoadMore = useCallback(
        (currentCtx) => async () => {
            const ctx = SearchContext.updatePage(currentCtx);

            await search(ctx, true);
        },
        [search]
    );

    const onLoadProduct = React.useCallback(
        async (code) => {
            dispatch({
                type: 'setContext',
                payload: await fetchProduct(context, code),
            });
        },
        [context]
    );

    const onOpenQuick = React.useCallback(
        (result, idx) => () => {
            setQuickviewIdx(idx);
            if (result.product.hasChildren) return;

            onLoadProduct(result.product.code);
        },
        [onLoadProduct]
    );

    const onPlayVideo = React.useCallback(
        (data) => () => {
            setVideoData(data);
            setShowModal(true);
        },
        []
    );

    const onCloseVideoModal = React.useCallback(() => {
        setShowModal(false);
        setVideoData(null);
    }, []);

    useEffect(() => {
        async function initialSearch() {
            await search(context);
            setHasSearchedInitial(true);
        }

        initialSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filtersMenu = (
        <SearchFiltersMenu
            facets={facets.searchFacets}
            onChange={onChange}
            hide={selectedTab !== 'product' || !facets.searchFacets.some((f) => f.options.length > 1)}
        />
    );

    /**
     * Order tabs by specified product category IDs. Unspecified tabs add to the end.
     */
    const tabOrder = useMemo(() => ['product'], []);

    return (
        <Section
            className={cx(className, `theme-${theme}`, 'SearchBlock')}
            style={style}
            header={
                <div className="SearchBlock__navigation container">
                    <div className="width__full-vw heading-backdrop" />
                    <SearchBar
                        className={`theme-${theme}`}
                        query={context?.query}
                        onTextChange={setQuery}
                        onSubmit={handleSubmit}
                        onClear={() => setQuery(null)}
                    />
                    <Section
                        className={cx(className, 'container')}
                        sidebarLeft={
                            !isMobile ? (
                                <SearchPagingSummary
                                    hide={!hasSearchedInitial}
                                    pageInfo={context.pageInfo}
                                    query={context.query}
                                />
                            ) : null
                        }
                        sidebarRight={
                            !isMobile ? (
                                filtersMenu
                            ) : (
                                <div>
                                    <SearchPagingSummary
                                        hide={!hasSearchedInitial}
                                        pageInfo={context.pageInfo}
                                        query={context.query}
                                    />
                                    {filtersMenu}
                                </div>
                            )
                        }
                    >
                        <SearchTabbedFacet facet={facets.tabFacet} onChange={onSearchCatagoryChange} order={tabOrder} />
                    </Section>
                </div>
            }
        >
            {isLoading ? (
                <div className="searchLoader">
                    <Spinner />
                </div>
            ) : null}
            <SearchFacetBreadcrumbs
                facets={facets.searchFacets}
                onChange={onChange}
                className={'SearchFacetBreadcrumbs'}
            ></SearchFacetBreadcrumbs>
            {!isLoading && !context.results.length ? (
                <HTMLRender content={resultsNotFoundDetails} />
            ) : (
                <InfiniteScroll
                    className="d-flex flex-column justify-content-center w-100"
                    useLoadIndicator
                    isDoneLoading={isDoneLoading}
                    isLoading={!hasSearchedInitial}
                    onLoad={onLoadMore(context)}
                >
                    <ResultsGrid
                        expandedChildren={
                            quickviewData ? (
                                <ProductQuickview
                                    viewType={quickviewMode}
                                    product={quickviewData}
                                    showLoader={!quickviewData.hasChildren}
                                    useRecaptcha={useRecaptcha}
                                    googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                                    checkoutPageLink={checkoutPageLink}
                                    retailSearch={storeLocatorLink}
                                    overrideIsMobile={isMobile}
                                    inputStatusTooltip={inputStatusTooltip}
                                    gtmListValue={GTM.TAGS.SEARCH_QUICK}
                                    onClose={() => setQuickviewIdx(-1)}
                                    totalQty={totalQty}
                                    useChildTable={useChildTable}
                                    useRetailer={useRetailer}
                                />
                            ) : null
                        }
                        expandedResultIndex={quickviewIdx}
                        gap={5}
                        className="SearchBlock__results--items"
                    >
                        {context.results?.map((result, idx) => (
                            <SearchResult
                                key={`${result.id}-${idx}`}
                                result={result}
                                active={idx === quickviewIdx}
                                onPlayVideo={onPlayVideo}
                                openQuickview={onOpenQuick(result, idx)}
                                closeQuickview={() => setQuickviewIdx(-1)}
                            />
                        ))}
                    </ResultsGrid>
                </InfiniteScroll>
            )}
            <VideoPlayerModal videoProps={videoData} show={showModal} onClose={onCloseVideoModal} />
        </Section>
    );
}

SearchBlock.propTypes = {
    style: px.object,
    className: px.string,
    onSearch: px.func,
    defaultPageSize: px.number,
    initialQuery: px.string,
    theme: px.string,
    quickviewMode: px.string,
    useRecaptcha: px.bool,
    googleRecaptchaV3Sitekey: px.string,
    checkoutPageLink: px.string,
    storeLocatorLink: px.string,
    inputStatusTooltip: px.string,
    resultsNotFoundDetails: px.string,
    relatedSearchCount: px.number,
    useChildTable: px.bool,
    useRetailer: px.bool
};

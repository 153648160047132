import React, { useCallback, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useViewport, useTranslation } from 'Common/hooks';
import { Product, Facet } from 'Common/types';
import { Translation } from 'Common/components/localization';
import { ImageModal, NumberInput } from 'Common/components/ui';
import { Grid, Header, Row, Cell } from 'Common/components/ui/Grid';
import ProductBadge from './ProductBadge';
import ProductPrice from './ProductPrice';
import { ProductVariant } from 'Common/models';

function buildColsFromAttributes(variants = []) {
    const cols = new Set();

    for (const variant of variants) {
        for (const { IsSpecification, AttributeName } of variant.Attributes) {
            if (IsSpecification) cols.add([AttributeName]);
        }
    }

    return [...cols];
}

export default function ProductListTable({
    facets,
    getName = (x) => x.Code,
    id: idProp = `PLT_${v4()}`,
    NotifyMe,
    notifyProps,
    onQuantityChange,
    onRowClick,
    quantities = {},
    showVariantImages = false,
    variants = [],
}) {
    const viewport = useViewport();
    const isMobile = useMemo(() => viewport.is.lt('md'), [viewport]);
    const [showModal, setShowModal] = useState(false);
    const [showModalCarousel, setShowModalCarousel] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [variantMedia, setVariantMedia] = useState([]);
    const shouldShowNotifyColumn = variants.some((v) => v.StockStatus === 'OutOfStock');
    const shouldShowQuantityColumn = Boolean(onQuantityChange) && variants.some((v) => v.StockStatus === 'InStock');
    const shouldShowActionsColumn = shouldShowNotifyColumn || shouldShowQuantityColumn;

    const tooltip = useTranslation('Commerce.Product.Varient.Images.ToolTip');
    const id = useRef(idProp);
    const key = useCallback((...args) => [id.current, ...args].join('__'), []);
    const cols = useMemo(
        () => facets?.map((f) => [f.Attribute, f.AttributeDisplayName]) ?? buildColsFromAttributes(variants),
        [facets, variants]
    );

    const closeModal = useCallback(() => setShowModal(false), []);

    const setShowModalSlider = useCallback(() => setShowModalCarousel(true), []);

    const onClickView = useCallback((vM) => {
        setVariantMedia(vM);
        setModalContent({
            type: vM[0].FileType,
            src: vM[0].Url,
            isMobile: isMobile,
            selectedItem: vM[0],
        });
        setShowModal(true);
    }, [isMobile]);


    return (
        <>
            <Grid className="ProductListTable">
                <Header>
                    <Cell
                        sticky="left"
                        className={cx('d-flex px-4', `justify-content-${showVariantImages ? 'between' : 'start'}`)}
                    >
                        <div className='pl-3 pr-3'>
                            <Translation id="Commerce.Product.Tabs.TypeId.Label" />
                        </div>
                        {showVariantImages ? (
                            <div>
                                <Translation id="Commerce.Product.Tabs.Images.Label" />
                            </div>
                        ) : null}
                    </Cell>
                    {!cols.length ? (
                        <Cell>&nbsp;</Cell>
                    ) : (
                        cols.map(([c, d]) => (
                            <Cell key={key('body', 'col', c)} className={cx('d-flex justify-content-center pl-3 pr-3')}>
                                {d || c}
                            </Cell>
                        ))
                    )}
                    {!shouldShowActionsColumn ? (
                        <Cell className={cx('d-flex justify-content-end pl-3 pr-0')} sticky="right" width="min-content" >
                            <div className="align-self-end pr-3">
                                <Translation id="Commerce.Product.Tabs.Price.Label" />
                            </div>
                            
                        </Cell>
                    ) : (
                        <Cell className={cx('d-flex justify-content-between pl-3 pr-0')} sticky="right" width="min-content">
                            <div className="align-self-start pl-3">
                                <Translation id="Commerce.Product.Tabs.Price.Label" />
                            </div>
                            <div  width="min-content" id="action-header">
                                &nbsp;
                            </div>
                        </Cell>)}
                </Header>
                {variants?.map((v) => (
                    <Row key={key('head', 'name', v.Code)}>
                        <Cell
                            onClick={onRowClick}
                            className={cx(showVariantImages ? '' : 'd-flex justify-content-start align-items-center')}
                            sticky="left"
                        >
                            <div
                                className={cx(
                                    `justify-content-${showVariantImages ? 'between header-column' : 'start'}`,
                                    'd-flex pl-4'
                                )}
                            >
                                <div
                                    className={cx(
                                        'd-flex flex-column align-items-start justify-content-end',
                                        showVariantImages ? 'mr-4' : ''
                                    )}
                                >
                                    <ProductBadge product={v} />
                                    <div>{getName(v)}</div>
                                </div>
                                {showVariantImages ? (
                                    <button
                                        className="btn btn-primary btn-sm gallery-btn"
                                        onClick={() => onClickView(v.GalleryMedia)}
                                        title={tooltip}
                                        disabled={!v.GalleryMedia.length || v.GalleryMedia[0].ContentId == 0}
                                    >
                                        <i className="far fa-images" />
                                    </button>
                                ) : null}
                            </div>
                        </Cell>
                        {!cols.length ? (
                            <Cell />
                        ) : (
                            cols.map(([c]) => (
                                <Cell
                                    key={key('body', 'cell', c, v.Code)}
                                    className={cx('d-flex align-items-center justify-content-center px-3')}
                                >
                                    {v.Attributes.find((a) => a.AttributeName === c)?.Values?.join(', ') ?? ''}
                                </Cell>
                            ))
                        )}
                        {!shouldShowActionsColumn ? (
                            <Cell className={cx('d-flex justify-content-end align-items-center pl-3')} sticky="right" >
                                <ProductPrice
                                    priceInfo={{
                                        price: v.Price,
                                        useQtySalePrice:
                                            v.Price.QtySalePrices != null && Object.keys(v.Price.QtySalePrices).length > 0,
                                    }}
                                    quantity={quantities[v.Code] ?? 0}
                                />
                            </Cell>
                        ) : (
                        <Cell className={cx('d-flex justify-content-end align-items-center pl-3')} sticky="right" >
                            <ProductPrice
                                priceInfo={{
                                    price: v.Price,
                                    useQtySalePrice:
                                        v.Price.QtySalePrices != null && Object.keys(v.Price.QtySalePrices).length > 0,
                                }}
                                quantity={quantities[v.Code] ?? 0}
                            />
                            {ProductVariant.isDisplayOnly(v) ? null : v.StockStatus === 'InStock' ? (
                                    <div className="num-input pr-1" style={{ width: '125px' }}>
                                        <NumberInput
                                            min={0}
                                            step={1}
                                            noDecimal
                                            value={quantities[v.Code] ?? 0}
                                            onChange={(n) => onQuantityChange(n, v.Code)}
                                            useStepBtns
                                        />
                                    </div>
                                ) : (
                                    <NotifyMe {...notifyProps} code={v.Code} />
                                )}
                        </Cell>)}
                    </Row>
                ))}
            </Grid>
            <div className="ProductGallery">
                <ImageModal
                    className="ProductGallery__Modal"
                    closeModal={closeModal}
                    isMobile={isMobile}
                    items={variantMedia}
                    modalContent={modalContent}
                    modalSize="xl"
                    setModalContent={setModalContent}
                    setShowModalSlider={setShowModalSlider}
                    showModal={showModal}
                    showCarousel={showModalCarousel}
                />
            </div>
        </>
    );
}

ProductListTable.propTypes = {
    facets: px.arrayOf(Facet),
    getName: px.func,
    id: px.string,
    isPlp: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.objectOf(px.any),
    onQuantityChange: px.func,
    onRowClick: px.func,
    quantities: px.objectOf(px.number),
    showVariantImages: px.bool,
    variants: px.arrayOf(Product),
};

import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Url } from 'Common/utils';
import { Translation } from 'Common/components/localization';

export default function PartialDetailsLinks({ className, productLink, retailSearch, useRetailer = false }) {
    const productUrl = React.useMemo(
        () => (Url.isAbsolute(productLink) && productLink ? Url.create(productLink) : null),
        [productLink]
    );

    const sourceSiteLabel = useTranslation('Commerce.CatalogNode.QuickView.ViewDetails');
    const targetSiteLabel = useTranslation('Commerce.CatalogNode.QuickView.ViewDetails.TargetSite', {
        linkDomain: productUrl?.host,
    });

    const label = React.useMemo(() => (productUrl?.host === location.host ? sourceSiteLabel : targetSiteLabel), [
        productUrl,
        sourceSiteLabel,
        targetSiteLabel,
    ]);

    const linkTarget = React.useMemo(() => (productUrl?.host === location.host ? '_self' : '_blank'), [productUrl]);
    const linkRel = React.useMemo(() => (productUrl?.host === location.host ? undefined : 'noopener noreferrer'), [
        productUrl,
    ]);

    return (
        <div className={cx('DetailsLinks', 'row justify-content-md-start justify-content-center', className)}>
            {productUrl ? (
                <a className="paragraph-3 mx-4 mr-sm-3 ml-sm-0" href={productLink} target={linkTarget} rel={linkRel}>
                    <strong>{label}</strong>
                    &nbsp;
                    <i className="fa fa-arrow-right mr-1" aria-hidden="true" />
                </a>
            ) : null}
            {retailSearch && useRetailer ? (
                <a className="paragraph-3 mx-4 mr-sm-0 ml-sm-3" href={retailSearch} target={linkTarget} rel={linkRel}>
                    <i className="fa fa-search mr-2" aria-hidden="true"></i>
                    <strong>
                        <Translation id="Commerce.Product.Link.RetailSearch.Label" />
                    </strong>
                </a>
            ) : null}
        </div>
    );
}

PartialDetailsLinks.propTypes = {
    className: px.string,
    productLink: px.string,
    retailSearch: px.string,
    useRetailer: px.bool
};
